import React, { useState, ChangeEvent } from 'react';
import { TextField } from '@care/react-component-lib';
import { Icon24InfoLocation } from '@care/react-icons';
import { Grid, Typography, Button, InputAdornment, SxProps, Theme, Box } from '@mui/material';
import { AmplitudeEvent, trackAmplitudeEvent } from '@/lib/analyticsHelper';

const classes: Record<string, SxProps<Theme>> = {
  zipCodeContainer: (theme) => ({
    backgroundColor: theme.palette.care.grey[50],
    display: 'flex',
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(3),
    },

    [theme.breakpoints.up('md')]: {
      borderRadius: '16px',
    },
  }),
  subheader: (theme) => ({
    fontSize: '1rem',
    padding: theme.spacing(1, 0, 0, 0),
  }),
  textFieldOverride: (theme) => ({
    maxWidth: '183px',
    minWidth: '0',
    height: '48px',
    '& .MuiInputBase-root': {
      padding: theme.spacing(0, 1),
    },
  }),
  headerWrapper: (theme) => ({
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 5, 0, 0),
    },

    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 0, 0, 2),
    },
  }),
  ctaWrapper: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  }),
  positionStart: (theme) => ({
    margin: theme.spacing(0),
  }),
  btn: (theme) => ({
    maxWidth: '156px',
    height: '40px',
    marginLeft: theme.spacing(2),
    fontSize: '1.125rem',
    padding: theme.spacing(0),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2),
    },
  }),
};

interface CaregiverZipCodeCTAProps {
  enrollmentURL: string;
  serviceShortName: string;
}

const CaregiverZipCodeCTA = ({ enrollmentURL, serviceShortName }: CaregiverZipCodeCTAProps) => {
  const zipCodeRegex = /^\d{0,5}$/;
  const [zip, setZip] = useState('');
  const enrollmentLink = zip ? `${enrollmentURL}&zipcode=${zip}` : `${enrollmentURL}`;

  const onZipCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!zipCodeRegex.test(e.target.value)) {
      return;
    }

    setZip(e.target.value);
  };

  const handleCtaBtn = () => {
    const amplitudeData = {
      cta_text: 'Start matching',
      cta_type: 'button',
    };

    trackAmplitudeEvent(AmplitudeEvent.ctaInteracted, amplitudeData);
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      window.location.href = `${enrollmentLink}`;
      handleCtaBtn();
    }
  };

  const headline = () => {
    let vertical = '';

    if (serviceShortName === 'cc') {
      vertical = 'child care';
    } else if (serviceShortName === 'sc') {
      vertical = 'senior care';
    } else if (serviceShortName === 'hk') {
      vertical = 'housekeepers';
    } else if (serviceShortName === 'tu') {
      vertical = 'tutors';
    } else if (serviceShortName === 'pc') {
      vertical = 'pet care';
    } else if (serviceShortName === 'cg') {
      vertical = 'personal services';
    } else if (serviceShortName === 'sn') {
      vertical = 'special needs';
    }

    return `Find trusted ${vertical} in your area`;
  };

  return (
    <Grid container sx={classes.zipCodeContainer} data-testid="caregiver-zip-code-cta">
      <Box sx={classes.headerWrapper}>
        <Typography variant="h2">{headline()}</Typography>
        <Typography sx={classes.subheader}>
          Enter a zip code to get matched with caregivers that meet your needs
        </Typography>
      </Box>
      <Box sx={classes.ctaWrapper}>
        <TextField
          autoFocus
          placeholder="Enter Zip Code"
          onKeyUp={handleKeyUp}
          onChange={onZipCodeChange}
          value={zip}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={classes.positionStart}>
                <Icon24InfoLocation />
              </InputAdornment>
            ),
          }}
          sx={classes.textFieldOverride}
        />
        <Button
          color="primary"
          variant="contained"
          size="medium"
          sx={classes.btn}
          onClick={handleCtaBtn}
          href={enrollmentLink}
          rel="nofollow">
          Start matching
        </Button>
      </Box>
    </Grid>
  );
};

export default CaregiverZipCodeCTA;
